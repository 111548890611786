
const initialState = { "state": 0, "lat": 0, "long": 0, "accur": 0, "alt": 0, "altaccur": 0, "time": 0 }

export function geolocReducer(state = initialState, action)
{
    switch(action.type)
    {
        case 'GEOLOC.UPDATE':
            return action.payload;
        
        default:
            return state
    }
}

export const geolocSeletor = ({geoloc}) => geoloc

export const geolocUpdate = (geoloc) => ({
    type: 'GEOLOC.UPDATE',
    payload: geoloc
})


let geolocManagerStore;
export default class geolocManager
{
    static internalSuccess(position)
    {
        console.log("GEOLOC OK");
        let geoloc = { "state": 1,
        "lat": position.coords.latitude,
        "long": position.coords.longitude,
        "accur": position.coords.accuracy,
        "alt": position.coords.altitude,
        "altaccur": position.coords.altitudeAccuracy,
        "time": new Date() }
        geolocManagerStore.dispatch(geolocUpdate(geoloc));
    }

    static internalError(error)
    {
        console.log("GEOLOC ERROR " + error.code + " : " + error.message);
    }

    static init(store)
    {
        console.log("GEOLOC INIT");
        geolocManagerStore = store;
        if (navigator.geolocation)
        {
            var geo_options = {
                enableHighAccuracy: true,
                maximumAge        : 30000,
                timeout           : 27000
              };
            navigator.geolocation.watchPosition(this.internalSuccess, this.internalError, geo_options);
        }
    }

    static get()
    {
        return geolocSeletor(geolocManagerStore.getState());
    }
}