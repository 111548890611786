import React, {Component} from 'react';
import { getPU } from '../helpers';
import { connect } from 'react-redux';
import { catalogSeletor } from '../store/catalogSelectors';

import Selector001Displayer from './Selector001';
import PdfViewer001Displayer from './PdfViewer001';
import Image001Displayer, {ImageBasic001Displayer} from './Image001';
import UnknowDisplayer from './Unknow';
import CrashDisplayer from './Crash';
import { Loader } from '../Loader';
import ImageScroll001Displayer from './ImageTest001';

class DisplayerComponent extends Component
{
    displayerList = {
        unknow: UnknowDisplayer,
        selector001: Selector001Displayer,
        image001: Image001Displayer,
        imageBasic001: ImageBasic001Displayer,
        imageScroll001: ImageScroll001Displayer,
        pdfViewer001: PdfViewer001Displayer,
        crash: CrashDisplayer
    };

    render ()
    {
        let DisplayerName = this.displayerList[getPU(this.props.catalog.data, "pageType")];
        if(DisplayerName==null) { DisplayerName = this.displayerList['unknow'] }
        return (
            <>
            <DisplayerName catalog={this.props.catalog.data}/>
            <Loader></Loader>
            </>
        );
    }
};

export const Displayer = connect(
    (state) => ({
        catalog: catalogSeletor(state)
    })
)(DisplayerComponent)