import React, {Component} from 'react';

import '../App.scss';
import { getPackageDataPath, getPU } from '../helpers';

export default class ButtonTemplate001 extends Component
{
    render ()
    {
        if(getPU(this.props.catalog,'icon', null)===null)
        {
            let lines = getPU(this.props.catalog, 'title', "").split('^');
            return (
                <svg width="100%" height="100%" viewBox="0, 0, 100, 100" preserveAspectRatio="xMidYMid meet">
                    <image width="100%" height="100%" xlinkHref={getPackageDataPath(this.props.catalog.img)} />
                    <text x="50%" y="50%" fill={getPU(this.props.catalog, "color", "white")} fontSize={getPU(this.props.catalog, "fontsize", 15)} fontWeight="500" textAnchor="middle" alignmentBaseline="central">
                        {
                            lines.map((line, index) => {
                                let dy = 1.2 * (index-(lines.length-1)/2);
                                return (<tspan key={index + line} x="50%" y="55%" dy={dy+"em"}>{line}</tspan>);
                            })
                        }
                    </text>
                </svg>
            );
        }

        return (
            <>
            <svg width="100%" height="100%" viewBox="0, 0, 100, 100" preserveAspectRatio="xMidYMid meet">
                <image width="100%" height="100%" xlinkHref={getPackageDataPath(this.props.catalog.img)} />
            </svg>

            <div style={{position: "absolute", height: "100%", width: "100%"}}>
                <svg width="100%" height="52%" viewBox="0 0 500 100" preserveAspectRatio="xMinYMid meet">
                    <text x="250" y="50" fontSize="150" fill={getPU(this.props.catalog, "color", "white")} textAnchor="middle" alignmentBaseline="central">{getPU(this.props.catalog, "title", "")}</text>
                </svg>

                <div className="flex justify-center" style={{overflow: 'hidden', height: "45%", width: "100%"}}>
                    <img src={getPackageDataPath(this.props.catalog.icon)} alt="" style={{ verticalAlign: "middle", objectFit: "contain"}}/>
                </div>
            </div>
            </>
        );
    }
};