import React, {Component} from 'react';
import { connect } from 'react-redux';
import { catalogSeletor } from './store/catalogSelectors';

import styles from './Loader.module.scss';

class LoaderComponent extends Component
{
    render ()
    {
        if(this.props.catalog.loading)
        {
            return ( <div className={styles['loader']}></div> );
        }
        return "";
    }
};

export const Loader = connect(
    (state) => ({
        catalog: catalogSeletor(state)
    })
)(LoaderComponent)