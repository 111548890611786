
const initialState = { "pageType": "test" }

export function catalogReducer(state = initialState, action)
{
    switch(action.type)
    {
        case 'CATALOG.UPDATE':
            return {
                package: action.payload.newCatalog.package,
                page: action.payload.newCatalog.path,
                data: action.payload.newCatalog.data,
                loading: 0
            };

        case 'CATALOG.LOAD':
            return {
                package: state.package,
                page: state.path,
                data: state.data,
                loading: 1
            };
        
        default:
            return state
    }
}