
const initialState = { "hours": 0, "minutes": 0, "seconds": 0, "wday": 0, "day": 0, "month": 0, "year": 0 }

export function datetimeReducer(state = initialState, action)
{
    switch(action.type)
    {
        case 'DATETIME.UPDATE':
            return action.payload;
        
        default:
            return state
    }
}

export const datetimeSeletor = ({datetime}) => datetime


export const datetimeUpdate = (datetime) => ({
    type: 'DATETIME.UPDATE',
    payload: datetime
})

let chronoManagerTime = null;
let chronoManagerSTime = null;
let chronoManagerState = false;
let chronoManagerShots = [];
export class chronoManager
{
    static reset()
    {
        chronoManagerTime = null;
        chronoManagerSTime = null;
        chronoManagerState = false;
        chronoManagerShots = [];
    }
    
    static start()
    {
        chronoManagerState = true;
        chronoManagerSTime = new Date();
    }

    static pause()
    {
        let pause = new Date();
        chronoManagerState = false;
        chronoManagerTime += pause-chronoManagerSTime;
        chronoManagerSTime = null;
    }

    static getState()
    {
        return chronoManagerState;
    }

    static getInfo()
    {
        let time = chronoManagerTime;
        if(chronoManagerState===true)
        {
            time += (new Date())-chronoManagerSTime;
        }

        return {
            "started": chronoManagerState,
            "ms": time%1000,
            "s": Math.floor(time/1000%60),
            "m": Math.floor(time/1000/60%60),
            "h": Math.floor(time/1000/3600),
        }
    }
    
    static getInfoToString()
    {
        let time = chronoManagerTime;
        if(chronoManagerState===true)
        {
            time += (new Date())-chronoManagerSTime;
        }

        return {
            "started": chronoManagerState,
            "ms": (time % 1000).toLocaleString('en-US', {
                minimumIntegerDigits: 3,
                useGrouping: false
            }),
            "s": Math.floor(time/1000%60).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false
            }),
            "m": Math.floor(time/1000/60%60).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false
            }),
            "h": Math.floor(time/1000/3600).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false
            }),
        }
    }

    static takeShot()
    {
        let mem = chronoManagerTime;
        if(chronoManagerState===true) {mem += ((new Date())-chronoManagerSTime);}

        let diff = mem;
        if(chronoManagerShots.length>0)
        {
            diff -= chronoManagerShots[chronoManagerShots.length-1].time;
        }

        chronoManagerShots.push({"time": mem, "diff": diff});
    }

    static getShots()
    {
        return chronoManagerShots;
    }
}