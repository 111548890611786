import React, {Component} from 'react';
import styles from './Template001.module.scss';

import '../App.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import startingamesRouteManager from '../startingames/startingamesRouteManager';
import { getPU, processLink } from '../helpers';
import WrapperContent from './WrapperContent';
import Button001 from './Button001';
import ButtonTemplate001 from './ButtonTemplate001';
import TopBar from '../TopBar';
library.add(fas);

export default class Template001 extends Component
{
    render ()
    {
        let divActionStyle = {
            background: getPU(this,"props.catalog.actionsBg", ""),
            borderRadius: "",
            border: "",
            borderColor: "",
            borderWidth: ""
        };

        if(this.props.catalog.actionsRadius==="none")
        {
            divActionStyle.borderRadius='0%';
        }

        if(this.props.catalog.actionsBorder!=null)
        {
            divActionStyle.border='solid';
            divActionStyle.borderColor=this.props.catalog.actionsBorder[1];
            divActionStyle.borderWidth=this.props.catalog.actionsBorder[0]+'px';
        }

        var titleFontSize = 5;
        var titleTextLine2 = "";
        if(this.props.catalog.title.length > 50) { titleFontSize = 3; }
        if(getPU(this.props.catalog, "title2", "") !== "")
        {
            titleFontSize = 3;
            titleTextLine2 = this.props.catalog.title2;
        }

        const divTitleStyle = {
            fontSize: titleFontSize+'vmin',
            height:'10vmin',
            background: this.props.catalog.titlebg,
            color: getPU(this.props.catalog, "color", "white"),
            borderRadius: '2vmin',
            position: "relative"
        };

        if(getPU(this.props.catalog, "titleBorder", null)!=null)
        {
            divTitleStyle.border='solid';
            divTitleStyle.borderColor=this.props.catalog.titleBorder[1];
            divTitleStyle.borderWidth=this.props.catalog.titleBorder[0]+'px';
        }

        let titleButtonStyle = {
            fontSize: titleFontSize+'vmin',
            height:'10vmin',
            background: getPU(this.props.catalog, "titleButton.bg", ""),
            color: getPU(this.props.catalog, "titleButton.color", "white"),
            borderRadius: '2vmin',
            position: "relative"
        };

        if(getPU(this.props.catalog, "titleButton.border", null)!=null)
        {
            titleButtonStyle.border='solid';
            titleButtonStyle.borderColor=this.props.catalog.titleButton.border[1];
            titleButtonStyle.borderWidth=this.props.catalog.titleButton.border[0]+'px';
        }
        
        return (
            <WrapperContent className={styles['wrapper-template']}>
                <TopBar></TopBar>
                <div className={"h-full flex content-start flex-row"}>

                    <div style={{width: "30vmin"}}>

                        <div className={"flex flex-col w-full " + styles['menu-container'] }>
                            <Button001 className={"mx-auto " + styles['TemplateButton']} onClick={() => startingamesRouteManager.goto('/')}>
                                <div className={"flex flex-col w-full my-auto"}>
                                    <FontAwesomeIcon className={"w-full mx-auto"} icon={["fas", "home"]} /> 
                                    <span className={"w-full m-auto text-center"}>Accueil</span>
                                </div>
                            </Button001>
                            <Button001 className={"mx-auto " + styles['TemplateButton']}  onClick={() => startingamesRouteManager.goBack()}>
                                <div className={"flex flex-col w-full my-auto"}>
                                    <FontAwesomeIcon className={"w-full mx-auto"} icon={["fas", "arrow-left"]} /> 
                                    <span className={"w-full m-auto text-center"}>Retour</span>
                                </div>
                            </Button001>
                        </div>

                        <div className={"w-full h-3/5 " + styles['actionsContainer']} style={{ overflow: 'auto', overflowX: 'hidden' }}>
                            {
                                this.props.catalog.actions.map(element => {

                                    let localStyle = {
                                        background: getPU(element,"bg", divActionStyle.background),
                                        borderRadius: divActionStyle.borderRadius,
                                        border: divActionStyle.border,
                                        borderColor: divActionStyle.borderColor,
                                        borderWidth: divActionStyle.borderWidth,
                                        color: getPU(this,"color", "white")
                                    };

                                    return (
                                        <Button001 key={JSON.stringify(element)} className={"mx-auto " + styles['TemplateButton']} onClick={() => processLink(element.link)} style={localStyle}>
                                            <ButtonTemplate001 catalog={element}/>
                                        </Button001>
                                    );
                                })
                            }
                        </div>

                    </div>
                    
                    <div className={"h-full w-full"}>
                        <div className={"flex"}>
                            <div className={"flex w-full"} style={{...divTitleStyle}}>
                                <div className={"m-auto text-center " + styles['text-header']}>{this.props.catalog.title}<br/>{titleTextLine2}</div>
                            </div>

                            {getPU(this.props.catalog, "titleButton", null) !== null &&
                                <div className={"flex " + styles['titleButton']} style={titleButtonStyle} onClick={() => processLink(this.props.catalog.titleButton.link)}>
                                    <div className={"m-auto text-center " + styles['text-header']}>{this.props.catalog.titleButton.title}</div>
                                </div>
                            }
                        </div>

                        <div className={"w-full"} style={{height:'calc(86vmin - 35px)', padding:'2vmin 0'}}>
                            <div className={"w-full h-full"} style={{padding:'0 2vmin', overflow:'hidden auto', overflowX: 'hidden', backgroundClip: "content-box", background: getPU(this.props.catalog, "contentBg", "unset")}}>
                                {this.props.children}
                            </div>
                        </div>
                    </div>

                </div>
            </WrapperContent>
        );
    }
};