import React, {Component} from 'react';

import '../App.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import { connect } from 'react-redux';
import { chronoManager, datetimeSeletor } from '../store/datetime';
import ClockGUI from './ClockGUI';
import PageTemplate001 from './PageTemplate001';
library.add(fas);

const wdayText = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
const monthText = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

class ClockPageComponent extends Component
{
    constructor(props) {
        super(props);
    
        this.state = {update: 1};
        this.myUpdate = this.myUpdate.bind(this);
        setTimeout(this.myUpdate, 10);
    }

    myUpdate()
    {
        this.setState({update: -1*this.state.update});
        setTimeout(this.myUpdate, 10);
    }

    render ()
    {
        let chrono = chronoManager.getInfoToString();

        let displayDirectionClass = "grid-cols-2";
        let displayDirectionStyle = {borderLeft: "1px solid grey"};
        if(window.innerWidth < window.innerHeight)
        {
            displayDirectionClass="grid-rows-2";
            displayDirectionStyle = {borderTop: "1px solid grey"};
        }

        return (
            <PageTemplate001 title="Date et Heure">
                <div className="grid-cols-2 grid-rows-2" style={{display:"none"}}></div>
                <div className="flex" style={{padding: "1vmin", height: "30%"}}>
                    <ClockGUI height="100%" time={this.props.datetime}/>

                    <div className="flex w-full">
                        <div className="my-auto" style={{fontSize: "5vmin"}}>
                        {Math.floor(this.props.datetime.hours).toLocaleString('en-US', {minimumIntegerDigits: 2})} h 
                        {Math.floor(this.props.datetime.minutes).toLocaleString('en-US', {minimumIntegerDigits: 2})} : 
                        {Math.floor(this.props.datetime.seconds).toLocaleString('en-US', {minimumIntegerDigits: 2})} <br/> {wdayText[this.props.datetime.wday]} {this.props.datetime.day} {monthText[this.props.datetime.month-1]} {this.props.datetime.year}
                        </div>
                    </div>
                </div>

<div className="flex flex-col w-full" style={{padding: "1vmin", height: "60%"}}>
                <div style={{padding: "0.2vmin 1vmin", background: "white", fontSize: "2vmin", fontWeight: "600", color: "black", borderTopLeftRadius: "1vmin", borderTopRightRadius: "1vmin"}}>Chronomètre</div>
                <div style={{border: "2px solid white", padding: "1vmin", height: "100%"}}>
                    <div className={"grid h-full "+displayDirectionClass}>
                        <div className="grid grid-rows-2" style={{padding: "1vmin"}}>

                            <div className="flex m-auto" style={{fontSize: "10vmin", lineHeight: "7vmin"}}>
                                <div className="flex flex-col">
                                    <div>{chrono.h}</div>
                                    <div className="mx-auto" style={{fontSize: "3vmin"}}>hr</div>
                                </div>
                                <div style={{margin: "0vmin 1vmin"}}>:</div>
                                <div className="flex flex-col">
                                    <div>{chrono.m}</div>
                                    <div className="mx-auto" style={{fontSize: "3vmin"}}>min</div>
                                </div>
                                <div style={{margin: "0vmin 1vmin"}}>:</div>
                                <div className="flex flex-col">
                                    <div>{chrono.s}</div>
                                    <div className="mx-auto" style={{fontSize: "3vmin"}}>sec</div>
                                </div>
                                <div style={{margin: "0vmin 0.5vmin"}}>.</div>
                                <div className="flex flex-col">
                                    <div>{chrono.ms}</div>
                                    <div className="mx-auto" style={{fontSize: "3vmin"}}> </div>
                                </div>
                            </div>

                            <div className="flex my-auto justify-evenly">
                                <button className="flex" style={{borderRadius: "50%", height: "13vmin", width: "13vmin", background: "black"}} title="Réinitialiser" onClick={() => chronoManager.reset()}><FontAwesomeIcon className="m-auto" style={{ width: "7vmin", height: "7vmin"}} icon={["fas", "undo-alt"]} /></button>
                                {chrono.started === false && <button className="flex" style={{borderRadius: "50%", height: "13vmin", width: "13vmin", background: "black"}} title="Lecture" onClick={() => chronoManager.start()}><FontAwesomeIcon className="m-auto" style={{ width: "7vmin", height: "7vmin"}} icon={["fas", "play"]} /></button>}
                                {chrono.started === true && <button className="flex" style={{borderRadius: "50%", height: "13vmin", width: "13vmin", background: "black"}} title="Pause" onClick={() => chronoManager.pause()}><FontAwesomeIcon className="m-auto" style={{ width: "7vmin", height: "7vmin"}} icon={["fas", "pause"]} /></button>}
                                <button className="flex" style={{borderRadius: "50%", height: "13vmin", width: "13vmin", background: "black"}} title="Mémoriser" onClick={() => chronoManager.takeShot()}><FontAwesomeIcon className="m-auto" style={{ width: "7vmin", height: "7vmin"}} icon={["fas", "flag"]} /></button>
                            </div>

                        </div>
                        <div style={{padding: "1vmin", overflow: "auto", fontSize: "2.5vmin", ...displayDirectionStyle}}>
                            <table className="w-full">
                                <tr style={{borderBottom: "1px solid white"}}>
                                    <th style={{width: "10%"}}></th>
                                    <th style={{width: "45%"}}>Temps mémorisé</th>
                                    <th style={{width: "45%"}}>Différence avec le précédent</th>
                                </tr>
                                {chronoManager.getShots().map((element, index) => {
                                    return (
                                        <tr>
                                            <th style={{textAlign: "left"}}>{index+1}</th>
                                            <td><div className="flex"><div className="flex m-auto">
                                                <div>{Math.floor(element.time/1000/60/60).toLocaleString('en-US', {minimumIntegerDigits: 2})}</div>
                                                <div style={{margin: "0vmin 0.5vmin"}}>:</div>
                                                <div>{Math.floor(element.time/1000/60%60).toLocaleString('en-US', {minimumIntegerDigits: 2})}</div>
                                                <div style={{margin: "0vmin 0.5vmin"}}>:</div>
                                                <div>{Math.floor(element.time/1000%60).toLocaleString('en-US', {minimumIntegerDigits: 2})}</div>
                                                <div style={{margin: "0vmin 0.5vmin"}}>.</div>
                                                <div>{Math.floor(element.time%1000).toLocaleString('en-US', {minimumIntegerDigits: 3})}</div>
                                            </div></div></td>
                                            <td><div className="flex"><div className="flex m-auto">
                                                <div>{Math.floor(element.diff/1000/60/60).toLocaleString('en-US', {minimumIntegerDigits: 2})}</div>
                                                <div style={{margin: "0vmin 0.5vmin"}}>:</div>
                                                <div>{Math.floor(element.diff/1000/60%60).toLocaleString('en-US', {minimumIntegerDigits: 2})}</div>
                                                <div style={{margin: "0vmin 0.5vmin"}}>:</div>
                                                <div>{Math.floor(element.diff/1000%60).toLocaleString('en-US', {minimumIntegerDigits: 2})}</div>
                                                <div style={{margin: "0vmin 0.5vmin"}}>.</div>
                                                <div>{Math.floor(element.diff%1000).toLocaleString('en-US', {minimumIntegerDigits: 3})}</div>
                                            </div></div></td>
                                        </tr>
                                        );
                                    })}
                            </table>
                        </div>
                    </div>
                </div>
</div>
            </PageTemplate001>
        );
    }
};

const ClockPage = connect(
    (state) => ({
        datetime: datetimeSeletor(state)
    })
)(ClockPageComponent)

export default ClockPage