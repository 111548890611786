import React, {Component} from 'react';
import startingamesRouteManager from '../startingames/startingamesRouteManager';

export default class ErrorComponent extends Component
{
    constructor(props)
    {
        super(props);
        this.goBack = this.goBack.bind(this);
    }

    goBack()
    {
      startingamesRouteManager.goBack();
    }

    render ()
    {
        return (
            <div className="flex" style={{ height:'100%', width:'100%'}}>
              <div className="m-auto justify-content-center" style={{ borderRadius: '5vh', padding: '5vh 15vh', color:'black', background:'rgba(255,255,255,0.6)' }}>
                <div style={{textAlign: 'center', fontSize: '10vh'}}>{this.props.title}</div>
                <div style={{textAlign: 'center', fontSize: '2vh'}}>{this.props.subtitle}</div>
                <div style={{textAlign: 'center', fontSize: '2vh', marginTop: '2vh'}}>
                  <button style={{ borderRadius: '2vh', background:'rgb(120,120,255)', textAlign: 'center', fontSize: '2vh', padding: '2vh', fontWeight:'700'}} onClick={this.goBack}>← Retour</button>
                </div>
              </div>
            </div>
          );
    }
};