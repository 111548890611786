import {useForm} from "react-hook-form"
import React, { useEffect } from "react"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Notyf } from 'notyf';

import styles from './form-crash.module.scss';

import { processLink } from '../../helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);

let formCrashMemory = null; //Memory for data save / recall

const wait = function (duration = 1000) {
    return new Promise((resolve) => {
        window.setTimeout(resolve, duration)
    })
}

const TAList = {
  "M2000": {"page": "type1/m2000"},
  "ALPHAJET": {"page": "type1/alphajet"},
  "RAFALE B": {"page": "type1/rafaleb"},
  "RAFALE C": {"page": "type1/rafalec"},
  "EUROFIGHTER": {"page": "type1/eurofighter"},
  "F16": {"page": "type1/f16"},

  "A330 PHENIX": {"page": "type2/a330-phenix"},
  "A400M": {"page": "type2/a400m"},
  "C130": {"page": "type2/c130"},
  "C130 J": {"page": "type2/c130j"},
  "C160": {"page": "type2/c160"},
  "CIRRUS": {"page": "type2/cirrus"},
  "CN 235 CASA": {"page": "type2/cn235-casa"},
  "DHC6 TWIN OTTER": {"page": "type2/dhc6-twin-otter"},
  "D 140 JODEL": {"page": "type2/d140-jodel"},
  "E3F": {"page": "type2/e3f"},
  "EMB 121 XINGU": {"page": "type2/emb121-xingu"},
  "FALCON 900": {"page": "type2/falcon900"},
  "FALCON 7X": {"page": "type2/falcon7x"},
  "FALCON 2000": {"page": "type2/falcon2000"},
  "K-C 135": {"page": "type2/kc135"},

  "AS 555 FENNEC": {"page": "type3/as555-fenec"},
  "AS 532 COUGAR": {"page": "type3/as532-cougar"},
  "EC 725 CARACAL": {"page": "type3/EC725-caracal"},
  "AS 355 ECUREUIL": {"page": "type3/as355-ecureuil"},
  "AS 555 FENNEC ALAT": {"page": "type3/as555-fennec-alat"},
  "SA 330 PUMA": {"page": "type3/sa330-puma"},
  "AS 332 SUPER PUMA": {"page": "type3/as332-super-puma"},
  "SA 342 GAZELLE ALAT": {"page": "type3/sa342-gazelle-alat"},

  "REAPER": {"page": "type4/reaper"},
}


//TODO Finir la vérification des services demandé sur la page contact
const schema = yup.object().shape({
    TA:         yup.string("Ce champs est obligatoirement une chaine de caractère").required("Ce champs est obligatoire"),
    TRO:        yup.number("Ce champs est obligatoirement un nombre").required("Ce champs est obligatoire"),
    ARM:        yup.string("Ce champs est obligatoirement une chaine de caractère").required("Ce champs est obligatoire"),
    FRET:       yup.string("Ce champs est obligatoirement une chaine de caractère").required("Ce champs est obligatoire"),
    POB:        yup.number("Ce champs est obligatoirement un nombre").required("Ce champs est obligatoire"),
    AVAR:       yup.string("Ce champs est obligatoirement une chaine de caractère").required("Ce champs est obligatoire"),
    TPSDIST:    yup.number("Ce champs est obligatoirement un nombre").required("Ce champs est obligatoire"),
    TPSDIST2:   yup.string("Ce champs est obligatoirement une chaine de caractère").required("Ce champs est obligatoire"),
});

const FormCrash = ({children}) => {
    const {register, handleSubmit, formState, errors} = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema)
    });

    const {isSubmitting, isSubmitted, isSubmitSuccessful, setError} = formState

    console.log(errors);
    console.log("HEY HEY SCHEMA");
    console.log(schema);

  const openTA = () => {
    let tavalue = document.getElementById("formCrash_TA").value;
    if(TAList[tavalue] !== undefined) { processLink(TAList[tavalue]); }
    else { alert("Type Inconnu - Impossible d'ouvrir une documentation"); }
  }

  const saveData = () => {
    console.log("FORM CRASH - SAVE DATA");
    formCrashMemory = {
      "ta":   document.getElementById("formCrash_TA").value,
      "tro":  document.getElementById("formCrash_TRO").value,
      "arm":  document.getElementById("formCrash_ARM").value,
      "fret": document.getElementById("formCrash_FRET").value,
      "pob":  document.getElementById("formCrash_POB").value,
      "avar": document.getElementById("formCrash_AVAR").value,
      "tpsdist":  document.getElementById("formCrash_TPSDIST").value,
      "tpsdist2": document.getElementById("formCrash_TPSDIST2").value
    };
  }

  const recallData = () => {
    console.log("FORM CRASH - RECALL DATA");
    document.getElementById("formCrash_TA").value="";
    document.getElementById("formCrash_TRO").value="";
    document.getElementById("formCrash_ARM").value="";
    document.getElementById("formCrash_FRET").value="";
    document.getElementById("formCrash_POB").value="";
    document.getElementById("formCrash_AVAR").value="";
    document.getElementById("formCrash_TPSDIST").value="";
    document.getElementById("formCrash_TPSDIST2").value="Minutes";
    if(formCrashMemory !== null)
    {
      document.getElementById("formCrash_TA").value=formCrashMemory.ta;
      document.getElementById("formCrash_TRO").value=formCrashMemory.tro;
      document.getElementById("formCrash_ARM").value=formCrashMemory.arm;
      document.getElementById("formCrash_FRET").value=formCrashMemory.fret;
      document.getElementById("formCrash_POB").value=formCrashMemory.pob;
      document.getElementById("formCrash_AVAR").value=formCrashMemory.avar;
      document.getElementById("formCrash_TPSDIST").value=formCrashMemory.tpsdist;
      document.getElementById("formCrash_TPSDIST2").value=formCrashMemory.tpsdist2;
    }
  }

  useEffect(() => {
    if(document.getElementById("formCrash_loaded").value<1)
    {
      document.getElementById("formCrash_loaded").value=1;
      recallData();
    }
  });

    const onSubmit = async data => {
      console.log(data);
      await wait(200);

      const notyf = new Notyf({
        duration: 1000,
        position: {
          x: 'right',
          y: 'top',
        },
      });

      notyf.success('Validé');

      formCrashMemory = null;
      recallData();
      saveData();

      // Create the new request 
      /*var xhr = new XMLHttpRequest();
      var url = 'http://demo.joachim-miens.com/mail'

      var final_data = JSON.stringify(data)

      xhr.open('POST', url);
      // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
      
      //xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
      //xhr.setRequestHeader('Access-Control-Allow-Headers', '*');

      //xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      //xhr.setRequestHeader('Charset', 'UTF-8');

      const notyf = new Notyf({
        duration: 1000,
        position: {
          x: 'right',
          y: 'top',
        },
      });

      xhr.onreadystatechange = function() {          
          if(xhr.readyState === 4 && xhr.status === 200) { 
            notyf.success('Mail envoyé');
            //alert(xhr.responseText); // Returns a 200 response if the submission is successful.
          } else if (xhr.readyState === 4){ 
            notyf.error("Erreur d'envoi");
            //alert(xhr.responseText); // Returns a 400 error the submission is rejected.          
          } 
      }


      // Sends the request 

      xhr.send(final_data)*/


  }

    console.log({isSubmitted, isSubmitSuccessful})

    return (
        <form className="space-y-4 py-4" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <input style={{display: "none"}} type="number" id="formCrash_loaded" defaultValue="0" />

            <div className="flex flex-wrap">
              <div className="w-full">
                <div className="flex flex-row flex-wrap">
                  <div className="w-full lg:w-1/3">
                    <label className="block text-white bg-black h-12 rounded-lg pt-1 my-1 mx-8 text-center text-3xl" htmlFor="formCrash_TA">Type d'appareil</label>
                  </div>
                  <div className="w-full lg:w-2/3 flex">
                    <input onInput={() => saveData()} placeholder="Type d'appareil" className="w-full h-12 px-3 my-1 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" id="formCrash_TA" list="formCrash_TA_list"  {...register('TA')}/>
                    <FontAwesomeIcon onClick={() => openTA()} className={"h-12 mx-auto"} icon={["fas", "external-link-alt"]} style={{cursor: "pointer", marginLeft: "1vmin", fontSize: "2rem"}}/>
                  </div>
                  <datalist id="formCrash_TA_list">
                    {Object.keys(TAList).map(function(key) { return(<option value={key}/>) })}
                  </datalist>
                  { errors?.TA !== undefined && <p className="text-red-500">{errors.TA?.message}</p> }
                </div>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-full">
                <div className="flex flex-row flex-wrap">
                  <div className="w-full lg:w-1/3">
                    <label className="block text-white bg-black h-12 rounded-lg pt-1 my-1 mx-8 text-center text-3xl" htmlFor="formCrash_TRO">TRO</label>
                  </div>
                  <div className="w-full lg:w-2/3">
                    <input onInput={() => saveData()} placeholder="TRO" className="w-full h-12 px-3 my-1 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="number" id="formCrash_TRO"  {...register('TRO')}/>
                  </div>
                  { errors?.TRO !== undefined && <p className="text-red-500">{errors.TRO?.message}</p> }
                </div>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-full">
                <div className="flex flex-row flex-wrap">
                  <div className="w-full lg:w-1/3">
                    <label className="block text-white bg-black h-12 rounded-lg pt-1 my-1 mx-8 text-center text-3xl" htmlFor="formCrash_ARM">Armement</label>
                  </div>
                  <div className="w-full lg:w-2/3">
                    <input onInput={() => saveData()} placeholder="Armement" className="w-full h-12 px-3 my-1 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" id="formCrash_ARM"  {...register('ARM')}/>
                  </div>
                  { errors?.ARM !== undefined && <p className="text-red-500">{errors.ARM?.message}</p> }
                </div>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-full">
                <div className="flex flex-row flex-wrap">
                  <div className="w-full lg:w-1/3">
                    <label className="block text-white bg-black h-12 rounded-lg pt-1 my-1 mx-8 text-center text-3xl" htmlFor="formCrash_FRET">FRET</label>
                  </div>
                  <div className="w-full lg:w-2/3">
                    <input onInput={() => saveData()} placeholder="FRET" className="w-full h-12 px-3 my-1 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" id="formCrash_FRET"  {...register('FRET')}/>
                  </div>
                  { errors?.FRET !== undefined && <p className="text-red-500">{errors.FRET?.message}</p> }
                </div>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-full">
                <div className="flex flex-row flex-wrap">
                  <div className="w-full lg:w-1/3">
                    <label className="block text-white bg-black h-12 rounded-lg pt-1 my-1 mx-8 text-center text-3xl" htmlFor="formCrash_POB">POB</label>
                  </div>
                  <div className="w-full lg:w-2/3">
                    <input onInput={() => saveData()} placeholder="POB" className="w-full h-12 px-3 my-1 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="number" min="0" id="formCrash_POB"  {...register('POB')}/>
                  </div>
                  { errors?.POB !== undefined && <p className="text-red-500">{errors.POB?.message}</p> }
                </div>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-full">
                <div className="flex flex-row flex-wrap">
                  <div className="w-full lg:w-1/3">
                    <label className="block text-white bg-black h-12 rounded-lg pt-1 my-1 mx-8 text-center text-3xl" htmlFor="formCrash_AVAR">Avarie(s)</label>
                  </div>
                  <div className="w-full lg:w-2/3">
                    <input onInput={() => saveData()} placeholder="Avarie(s)" className="w-full h-12 px-3 my-1 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" id="formCrash_AVAR"  {...register('AVAR')}/>
                  </div>
                  { errors?.AVAR !== undefined && <p className="text-red-500">{errors.AVAR?.message}</p> }
                </div>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-full">
                <div className="flex flex-row flex-wrap">
                  <div className="w-full lg:w-1/3">
                    <label className="block text-white bg-black h-12 rounded-lg pt-1 my-1 mx-8 text-center text-3xl" htmlFor="formCrash_TPSDIST">Tps Distance au posé</label>
                  </div>
                  <div className="w-full lg:w-2/3 flex">
                    <input onInput={() => saveData()} placeholder="Tps Distance au posé" className="w-full h-12 px-3 my-1 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="number" id="formCrash_TPSDIST"  {...register('TPSDIST')}/>
                    <select onInput={() => saveData()} placeholder="Unitée" defaultValue="Minutes" className="w-full h-12 px-3 my-1 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" style={{marginLeft: "1vmin"}} id="formCrash_TPSDIST2"  {...register('TPSDIST2')}>
                      <option>Minutes</option>
                      <option>Nautique</option>
                    </select>
                  </div>
                  { errors?.TPSDIST !== undefined && <p className="text-red-500">{errors.TPSDIST?.message}</p> }
                </div>
              </div>
            </div>
            
            <div className="flex flex-wrap">
              <div className="w-1/2 m-auto">

                <button style={{display: "none"}} className={"text-white font-bold py-4 w-full rounded-full text-3xl md:text-4xl " + styles['btn-1']}>
                  Valider
                </button>

              </div>
            </div>
            <script src="https://cdn.jsdelivr.net/npm/notyf@3/notyf.min.js" type="text/javascript" />
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/notyf@3/notyf.min.css"/>
        </form>
    )
}

export default FormCrash