import React, {Component} from 'react';

import '../App.scss';
import { getPackageDataPath, getPU, processLink } from '../helpers';
import styles from './Image001.module.scss';

import {Image001Common} from './Image001'

export default class ImageScroll001Displayer extends Component
{
    render ()
    {
        return (
            <div style={{width: "100%", height: "100%", overflow:"auto"}}>
            <Image001Common catalog={this.props.catalog} height={getPU(this.props.catalog, "factor", "180%")}>
                
                <svg width="100%" height="100%" viewBox={"0 0 "+this.props.catalog.img[1]+" "+this.props.catalog.img[2]} preserveAspectRatio="xMidYMid meet">
                    <image width="100%" height="100%" xlinkHref={getPackageDataPath(this.props.catalog.img[0])} />

                    {this.props.catalog.imgLinks.map(element => {
                    return (
                        <path key={JSON.stringify(element)} className={styles['pathlink']} d={element.path} onClick={() => processLink(element.link)} />
                        );
                    })}
                </svg>
                
            </Image001Common>
            </div>
        );
    }
};