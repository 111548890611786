import React, { Component } from 'react';
import styles from './Contact.module.scss';
import FormCrash from './form/form-crash';

export default class Contact extends Component
{
  render() {
    return (
        <div className={styles['contact']}>
            <div className={styles['contactForm']}>
                <p>* Champs requis</p>
                <FormCrash></FormCrash>
            </div>
            <img className={styles['contactImg']+" hidden md:flex"} src="./contact.jpg" alt="contact image"/>
        </div>
    )
  }
}