import React, {Component} from 'react';
import styles from './Button001.module.scss';

export default class Button001 extends Component
{
    render ()
    {
        if(this.props.hide)
        {
            return (<div className={ this.props.className + " " + styles['button001'] }></div>);
        }

        return (
            <div className={ this.props.className + " " + styles['button001'] }>
                <div className="flex justify-center" onClick={ this.props.onClick } style={this.props.style}>
                    { this.props.children }
                </div>
            </div>
        );
    }
};