import axios from "axios";
import { getRootPath } from "../helpers";

let projectsManagerIndex = {};
let projectsManagerProjects = {};
export class projectsManager
{
    static init()
    {
        projectsManagerIndex = {};
        projectsManagerProjects = {};
    }

    static load(fct)
    {
        axios.get(getRootPath()+'projects/index.json')
          .then(res => {
            projectsManagerIndex = res.data;

            projectsManagerIndex.projects.forEach(element => {
                axios.get(getRootPath()+'projects/'+element+'/index.json')
                    .then(res => {
                        projectsManagerProjects[element] = res.data;
                        if(projectsManagerIndex.projects.length==Object.keys(projectsManagerProjects).length)
                        {
                            
                            console.log("load projects finish");
                            console.log(projectsManagerProjects);
                            fct();
                        }
                    })
            });

          })
    }

    static getProjects()
    {
        return projectsManagerProjects;
    }
}