import React, {Component} from 'react';

import '../App.scss';
import startingamesRouteManager from '../startingames/startingamesRouteManager';
import { getPackageDataPath, getPU } from '../helpers';
import Template001 from '../components/Template001';

export default class PdfViewer001Displayer extends Component
{
    constructor(props)
    {
        super(props);
        this.gotoHome = this.gotoHome.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
    }

    gotoHome()
    {
        startingamesRouteManager.goto('/');
    }

    gotoPrevious()
    {
        startingamesRouteManager.goBack();
    }


    pdfRenderer(pdfUrl)
    {
        /*// If absolute URL from the remote server is provided, configure the CORS
        // header on that server.
        var url = '';

        // Loaded via <script> tag, create shortcut to access PDF.js exports.
        //pdfjsLib = window['pdfjs-dist/build/pdf'];

        // The workerSrc property shall be specified.
        //pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';

        // Will be using promises to load document, pages and misc data instead of
        // callback.
        const loadingTask = pdfjsLib.getDocument(url);
        loadingTask.promise
        .then(function (doc) {
            const numPages = doc.numPages;
            console.log("# Document Loaded");
            console.log("Number of Pages: " + numPages);
            console.log();

            let lastPromise; // will be used to chain promises
            lastPromise = doc.getMetadata().then(function (data) {
            console.log("# Metadata Is Loaded");
            console.log("## Info");
            console.log(JSON.stringify(data.info, null, 2));
            console.log();
            if (data.metadata) {
                console.log("## Metadata");
                console.log(JSON.stringify(data.metadata.getAll(), null, 2));
                console.log();
            }
            });

            const loadPage = function (pageNum) {
            return doc.getPage(pageNum).then(function (page) {
                console.log("# Page " + pageNum);
                const viewport = page.getViewport({ scale: 1.0 });
                console.log("Size: " + viewport.width + "x" + viewport.height);
                console.log();
                return page
                .getTextContent()
                .then(function (content) {
                    // Content contains lots of information about the text layout and
                    // styles, but we need only strings at the moment
                    const strings = content.items.map(function (item) {
                    return item.str;
                    });
                    console.log("## Text Content");
                    console.log(strings.join(" "));
                })
                .then(function () {
                    console.log();
                });
            });
            };
            // Loading of the first page will wait on metadata and subsequent loadings
            // will wait on the previous pages.
            for (let i = 1; i <= numPages; i++) {
            lastPromise = lastPromise.then(loadPage.bind(null, i));
            }
            return lastPromise;
        })
        .then(
            function () {
            console.log("# End of Document");
            },
            function (err) {
            console.error("Error: " + err);
            }
        );*/


    }

    componentDidMount()
    {
        this.pdfRenderer(this.props.catalog.pdf);
    }
    render ()
    {
        return (
            
            <Template001 catalog={this.props.catalog}>
                <iframe
                    title="PDF-Viewer"
                    width="100%"
                    height="100%"
                    src={"pdfviewer/web/viewer.html?file=../../" + getPackageDataPath(this.props.catalog.pdf) + "#page=" + getPU(this.props.catalog, "page", 1)}>
                </iframe>
            </Template001>
        );
    }
};