import React, { Component } from 'react';
import styles from './Projects.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { getPU } from '../helpers';
import startingamesRouteManager from '../startingames/startingamesRouteManager';
import ProjectCard from './ProjectCard';
import { projectsManager } from '../store/projectsManager';
library.add(fas);

export default class Projects extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {filters: false};

        this.filtersToggle = this.filtersToggle.bind(this);
    }

    filtersToggle()
    {
        this.setState({filters: !this.state.filters});
    }

    render() {
        let projects = projectsManager.getProjects();
        return (
        <div className={styles['projects']}>
            <div className={styles['filtersCollapse']}>
                <div className={"flex "+((this.state.filters===true) ? "" : "flex-col")}>
                    <button className={styles['filtersButton']} onClick={() => this.filtersToggle()}><FontAwesomeIcon icon={["fas", ((this.state.filters===true) ? "angle-left" : "angle-right")]} /></button>
                    <div className={"my-auto "+styles['filtersText']+" "+((this.state.filters===true) ? "" : styles['filtersCollapseText'])}>Filtres </div>
                </div>

                {this.state.filters===true &&
                    <div><br/>Section en cours<br/> de développement</div>
                }

            </div>

            <span className={styles['lineH']}></span>

            <div className={styles['centerBlock']}>
                <input placeholder="Recherche" className={styles['searchInput']}></input>
                <div className={"grid 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 "+styles['content']}>
                    
                    {Object.keys(projects).map(function(key) {
                        return <ProjectCard slug={"/projets/"+key} title={projects[key].title} date={projects[key].date} img={"/projects/"+key+"/"+projects[key].img} desc={projects[key].desc}/>;
                    })}

                </div>
            </div>
        </div>
        )
    }
}