import React, { Component } from 'react';
import styles from './Wrapper001.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { getPU } from '../helpers';
import startingamesRouteManager from '../startingames/startingamesRouteManager';
library.add(fas);

export default class Wrapper001 extends Component
{
  render() {
    return (
      <div className={styles['wrapper001']}>
          <div className={styles['topBar']}>

            {getPU(this.props, "title", null)===null &&
                <div className={styles['topBarButton']} onClick={() => startingamesRouteManager.goto("/")}>
                    <img src="/data/myicon.jpg"/>
                </div>
            }

            {getPU(this.props, "title", null)!==null &&
                <div className={styles['topBarButton']} onClick={() => startingamesRouteManager.goBack()}>
                    <FontAwesomeIcon className="m-auto" icon={["fas", "arrow-left"]} />
                </div>
            }

            <div className="my-auto">{getPU(this.props, "title", "CHUTIN Nicolas")}</div>

          </div>
          <div className={styles['content']} style={this.props.style}>
            {this.props.children}
          </div>
          <div className={styles['footer']}>
            <div>© CHUTIN Nicolas – Tous droits réservés</div>
            <div style={{marginLeft: 'auto', cursor: "pointer"}} onClick={() => startingamesRouteManager.goto("/mentions_legales")}>Mentions légales</div>
          </div>
      </div>
    )
  }
}