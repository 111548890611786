import React, { Component } from 'react';
import styles from './WrapperContent.module.scss';

export default class WrapperContent extends Component
{
  render() {
    return (
      <div className={styles['wrapper-content'] + " " + this.props.className}>
          { this.props.children }
      </div>
    )
  }
}