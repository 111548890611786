import React, {Component} from 'react';

import '../App.scss';


export default class Autres extends Component
{
    static getName()
    {
        return "Autres";
    }

    render ()
    {
        return (
            <>
                <p style={{fontSize: "5vmin"}}>.</p>
            </>
        );
    }
};