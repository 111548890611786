import startingamesRouteManager from "./startingames/startingamesRouteManager";

export function getPU(data, get, def = null)
{
    get.split('.').forEach(element => {
        if(typeof(data)=='undefined') {return def;}
        if(data===null) {return def;}
        data=data[element];
    });

    if(typeof(data)=='undefined') {return def;}
    return data;
}

export function getPackageDataPath(data)
{
    if(typeof(data)=='undefined') {return "";}
    if(data[0]==='/') { return data.substring(1); }
    return ("./packages/"+startingamesRouteManager.getPath().split('/')[1]+"/data/"+data);
}

export function processLink(link)
{
    if(link==null){return processLink2(link)}
    if(link[0]==null){return processLink2(link)}
    if(link[1]==null){return processLink2(link)}

    if(link[0]==="page")
    {
        startingamesRouteManager.goto("/"+startingamesRouteManager.getPath().split('/')[1]+"/"+link[1].replaceAll('/','_'))
    }
    else { alert("Invalid Link :\n"+link); }
    
    console.warn("Using old link method : ");
    console.warn(link);
}

export function processLink2(link)
{
    if(link==null){return}

    var tags = "";
    if(getPU(link, "tags", null) !== null)
    {
        tags="/"+link.tags;
    }

    startingamesRouteManager.goto("/"+getPU(link, "package", startingamesRouteManager.getPath().split('/')[1])+"/"+link.page.replaceAll('/','_')+tags)
}

export function isElectron() {
    // Renderer process
    if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
        return true;
    }

    // Main process
    if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
        return true;
    }

    // Detect the user agent when the `nodeIntegration` option is set to false
    if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
        return true;
    }

    return false;
}

let rootpath = "";

export function initApp()
{
    if(isElectron())
    {
        console.log("Running on Electron ...");
        //rootpath=require('electron').remote.app;
    }
    else
    {
        console.log("Running on Web Browser ...");
        rootpath="/";
    }
}

export function getRootPath()
{
    return rootpath;
}
