import React, { Component } from 'react';
import styles from './MainTemplate.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { getPU } from '../helpers';
import startingamesRouteManager from '../startingames/startingamesRouteManager';
library.add(fas);

export default class MainTemplate extends Component
{
  render() {
    return (
      <div className={styles['maintemplate']}>
          <div className={styles['menu']}>
            <button className={this.props?.menu==="/" && styles['active']} onClick={() => startingamesRouteManager.goto("/")}><FontAwesomeIcon icon={["fas", "home"]} /> A propos</button>
            <button className={this.props?.menu==="projets" && styles['active']} onClick={() => startingamesRouteManager.goto("/projets")}><FontAwesomeIcon icon={["fas", "tasks"]} /> Projets</button>
            <button className={this.props?.menu==="contact" && styles['active']} onClick={() => startingamesRouteManager.goto("/contact")}><FontAwesomeIcon icon={["fas", "address-card"]} /> Contact</button>
          </div>
          <div className={styles['content']}>
            {this.props.children}
          </div>
      </div>
    )
  }
}