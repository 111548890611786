import React, {Component} from 'react';
import '../App.scss';
import Template001 from '../components/Template001';
import FormCrash from '../components/form/form-crash';

export default class CrashDisplayer extends Component
{
    render ()
    {
        return (
            
            <Template001 catalog={this.props.catalog}>

                <FormCrash></FormCrash>

            </Template001>
            
        );
    }
};