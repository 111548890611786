import {combineReducers, createStore} from 'redux'
import { startingamesRouteManagerReducer } from '../startingames/startingamesRouteManager'
import { catalogReducer } from './catalogReducer'
import { datetimeReducer } from './datetime'
import { geolocReducer } from './geoloc'

const store = createStore(
    combineReducers({
        catalog: catalogReducer,
        route: startingamesRouteManagerReducer,
        datetime: datetimeReducer,
        geoloc: geolocReducer
    }),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store