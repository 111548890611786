import React, {Component} from 'react';

import '../App.scss';
import Template001 from '../components/Template001';
import { getPU, processLink } from '../helpers';
import TilesContainer001 from '../components/TilesContainer001';
import Button001 from '../components/Button001';
import ButtonTemplate001 from '../components/ButtonTemplate001';

export default class Selector001Displayer extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {filter: ""};
        this.updateFilter = this.updateFilter.bind(this);
    }

    updateFilter()
    {
        let newFilter = "";
        if(getPU(document.getElementById("filter"), "value", null)!== null)
        {
            newFilter = document.getElementById("filter").value.toLowerCase();
        }
        this.setState({ filter: newFilter });
    }

    render ()
    {
        let divTileStyle = {
            background: getPU(this,"props.catalog.tilesBg", ""),
            borderRadius: "",
            border: "",
            borderColor: "",
            borderWidth: ""
        };

        if(this.props.catalog.tilesRadius==="none")
        {
            divTileStyle.borderRadius='0%';
        }

        if(this.props.catalog.tilesBorder!=null)
        {
            divTileStyle.border='solid';
            divTileStyle.borderColor=this.props.catalog.tilesBorder[1];
            divTileStyle.borderWidth=this.props.catalog.tilesBorder[0]+'px';
        }

        //divTileStyle = {backgroundColor: "green"};

        let nbCols = getPU(this,"props.catalog.tilesCol", 6);
        let nbRows = getPU(this,"props.catalog.tilesRowMin", 1);

        let tilesToDisp = this.props.catalog.tiles;
        if(getPU(this,"props.catalog.tilesFilter", false)===true)
        {
            let filter = this.state.filter;
            tilesToDisp = this.props.catalog.tiles.filter(function (tile) { return tile.filterTags?.toLowerCase().includes(filter); });
        }

        return (
            <Template001 catalog={this.props.catalog}>

                {getPU(this,"props.catalog.tilesFilter", false)===true &&
                    <div className="w-full" style={{marginBottom: "2vmin"}}>
                        <input className="w-full" id="filter" type="text" placeholder="Fitrer ..." style={{color: "black", height: "4vh"}} onChange={() => this.updateFilter()}></input>
                    </div>
                }

                <TilesContainer001 col={nbCols} minRow={nbRows} maxHeight='83'>

                    {tilesToDisp.map(element => {

                        let localStyle = {
                            background: getPU(element,"bg", divTileStyle.background),
                            borderRadius: divTileStyle.borderRadius,
                            border: divTileStyle.border,
                            borderColor: divTileStyle.borderColor,
                            borderWidth: divTileStyle.borderWidth,
                            color: getPU(this,"color", "white")
                        };

                        if(getPU(element,"border", null)!=null)
                        {
                            localStyle.border='solid';
                            localStyle.borderColor=element.border[1];
                            localStyle.borderWidth=element.border[0]+'px';
                        }

                        return (
                                <Button001 key={JSON.stringify(element)} hide={getPU(element,"hide", false)} onClick={() => processLink(element.link)} style={localStyle}>
                                    <ButtonTemplate001 catalog={element}/>
                                </Button001>
                            );
                    })}

                </TilesContainer001>

            </Template001>
        );
    }
};