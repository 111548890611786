import React, {Component} from 'react';

import '../App.scss';

import {APP_VERSION_MMFN} from '../version';


export default class Informations extends Component
{
    static getName()
    {
        return "Informations";
    }

    render ()
    {
        return (
            <>
                <div className="loadericon">
                    <img src="./mainlogowhite.png" alt=""></img>
                    <div style={{color:"white"}}>FAD - First Aid Decision</div>
                </div>

                <p style={{fontSize: "2vmin"}}>Version  : {APP_VERSION_MMFN()}</p>
            </>
        );
    }
};