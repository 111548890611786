import React, {Component} from 'react';
import { isElectron } from './helpers';
import styles from './TopBar.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { connect } from 'react-redux';
import { chronoManager, datetimeSeletor } from './store/datetime';
import geolocManager from './store/geoloc';
import startingamesRouteManager from './startingames/startingamesRouteManager';
library.add(fas);
library.add(far);

let ipc = null;

if(isElectron())
{
    const { ipcRenderer } = window.require('electron');
    ipc = ipcRenderer;
}

class TopBarComponent extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {maximized: false, leftMenu: false};
        this.windowButton = this.windowButton.bind(this);
        this.closeButton = this.closeButton.bind(this);
        
        ipc?.on('response-window', (event, arg) => {
            if(arg==="isMaximized") { this.setState({ maximized: true }); }
            if(arg==="isRestored") { this.setState({ maximized: false }); }
        });
    }

    windowButton(action)
    {
        ipc?.send('request-window', action);
    }

    closeButton()
    {
        if(window.confirm("Quitter FAD ?\nLe contenu des formulaires sera perdu !"))
        {
            ipc?.send('request-window', "close");
        }
    }

    render ()
    {
        let lat = geolocManager.get().lat.toFixed(5);
        let long = geolocManager.get().long.toFixed(5);
        let accur = geolocManager.get().accur.toFixed(0);
        if(geolocManager.get().accur<1) { accur = "<1" }
        if(geolocManager.get().accur>999) { accur = ">1k" }

        let hoursColor = "white";
        let hoursColor2 = "rgba(0,0,0,0)";
        if(chronoManager.getState()===true && this.props.datetime.seconds%2===1)
        {
            hoursColor = "rgba(0,0,0,0)";
            hoursColor2 = "white";
        }

        return ( <>
            <div className={"noselect flex justify-between flex-row "+styles["topbar"]}>

                    <div className={"flex flex-nowrap"} style={{whiteSpace: "nowrap"}}>

                        {this.state.leftMenu===false &&
                        <div className={"flex " + styles['topbarBloc']} title="Menu Latéral" onClick={() => this.setState({ leftMenu: true })}>
                            <FontAwesomeIcon style={{width: "35px", height: "100%", padding: "5px 0"}} icon={["fas", "bars"]} />
                        </div>}

                        {this.state.leftMenu===true &&
                        <div className={"flex " + styles['topbarBloc']} title="Menu Latéral" onClick={() => this.setState({ leftMenu: false })}>
                            <FontAwesomeIcon style={{width: "35px", height: "100%", padding: "5px 0"}} icon={["fas", "times"]} />
                        </div>}

                        <div className={"flex " + styles['topbarBloc']} onClick={() => startingamesRouteManager.goto('/_clock')}>
                            <FontAwesomeIcon style={{width: "35px", height: "100%", padding: "5px 0"}} icon={["far", "clock"]} />
                            <div style={{position: "relative", textAlign: "center", color: hoursColor}}>
                            {Math.floor(this.props.datetime.hours).toLocaleString('en-US', {minimumIntegerDigits: 2})} h 
                            {Math.floor(this.props.datetime.minutes).toLocaleString('en-US', {minimumIntegerDigits: 2})} : 
                            {Math.floor(this.props.datetime.seconds).toLocaleString('en-US', {minimumIntegerDigits: 2})}<br/>
                            {Math.floor(this.props.datetime.day).toLocaleString('en-US', {minimumIntegerDigits: 2})} / 
                            {Math.floor(this.props.datetime.month).toLocaleString('en-US', {minimumIntegerDigits: 2})} / 
                            {Math.floor(this.props.datetime.year).toLocaleString('en-US', {minimumIntegerDigits: 4, useGrouping: false})}
                                <div style={{position: "absolute", top: 0, left: 0, right: 0, bottom: 0, color: hoursColor2}}>
                                    Chrono<br/>Actif
                                </div>
                            </div>
                        </div>

                        <div className={"flex " + styles['topbarBloc']} onClick={() => startingamesRouteManager.goto('/_gps')}>
                            <FontAwesomeIcon style={{width: "35px", height: "100%", padding: "5px 0"}} icon={["fas", "map-marker-alt"]} />
                            {geolocManager.get().state === 0 &&
                                <div style={{textAlign: "center"}}>Position GPS<br/>Inconnue</div>
                            }
                            {geolocManager.get().state === 1 &&
                                <div>Lat : {lat} <br/> Long : {long}</div>
                            }
                            <div className="my-auto" style={{textAlign: "center", paddingLeft: "1vmin"}}>
                            {geolocManager.get().state === 1 &&
                                <>± {accur}m</>
                            }
                            </div>
                        </div>

                    </div>

                    <div className={"w-full flex " + styles['topbarTitle']}>
                        <div className="m-auto">FAD - First Aid Decision</div>
                    </div>

                    
                    {isElectron() &&
                    <div className="flex flex-nowrap">
                        <button className={"my-auto " + styles['winButton']} onClick={() => this.windowButton("minimize")} title="Réduire"> 
                            <FontAwesomeIcon icon={["far", "window-minimize"]} />
                        </button>
                        {this.state.maximized === true &&
                        <button className={"my-auto " + styles['winButton']} onClick={() => this.windowButton("restore")} title="Réduire"> 
                            <FontAwesomeIcon icon={["far", "window-restore"]} />
                        </button>}
                        {this.state.maximized === false &&
                        <button className={"my-auto " + styles['winButton']} onClick={() => this.windowButton("maximize")} title="Agrandir"> 
                            <FontAwesomeIcon icon={["far", "window-maximize"]} />
                        </button>}
                        <button className={"my-auto " + styles['winButton'] + " " + styles['winButtonClose']} onClick={this.closeButton} title="Fermer"> 
                            <FontAwesomeIcon icon={["far", "window-close"]} />
                        </button>
                    </div>}

                </div>

            {this.state.leftMenu===true &&
            <div className={"flex flex-row " + styles["topbarMenu"]}>
                <div className={"flex flex-col " + styles["topbarMenuMenu"]}>
                                
                    <div className={"flex " + styles['menu-menu-el']} onClick={() => startingamesRouteManager.goto('/')}>
                        <div className="flex flex-nowrap content-center my-auto">
                            <FontAwesomeIcon style={{  padding: "2vmin 1vmin 0vmin 1vmin", width: "5vmin", height: "5vmin"}} icon={["fas", "home"]}/>
                            <div style={{fontSize: "5vmin"}}>Accueil</div>
                        </div>
                    </div>

                    <div className={"flex " + styles['menu-menu-el']} onClick={() => startingamesRouteManager.goBack()}>
                        <div className="flex flex-nowrap content-center my-auto">
                            <FontAwesomeIcon style={{ padding: "2vmin 1vmin 0vmin 1vmin", width: "5vmin", height: "5vmin"}} icon={["fas", "arrow-left"]}/>
                            <div style={{fontSize: "5vmin"}}>Retour</div>
                        </div>
                    </div>

                    <div className={"flex " + styles['menu-menu-el']} onClick={() => startingamesRouteManager.goto('/_settings/informations')}>
                        <div className="flex flex-nowrap content-center my-auto">
                            <FontAwesomeIcon style={{  padding: "2vmin 1vmin 0vmin 1vmin", width: "5vmin", height: "5vmin"}} icon={["fas", "cog"]} />
                            <div style={{fontSize: "5vmin"}}>Paramètres</div>
                        </div>
                    </div>

                </div>
                <div className="w-full" onClick={() => this.setState({ leftMenu: false })}>
                    
                </div>
            </div>}

            </>
        );
    }
};

const TopBar = connect(
    (state) => ({
        datetime: datetimeSeletor(state)
    })
)(TopBarComponent)

export default TopBar