import React, {Component} from 'react';
import styles from './index.module.scss';

import '../App.scss';

import Informations from './Informations'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import startingamesRouteManager from '../startingames/startingamesRouteManager';
import WrapperContent from '../components/WrapperContent';
import Autres from './Autre';
import TopBar from '../TopBar';
library.add(fas);

export default class Settings extends Component
{
    sectionsList = {
        informations: Informations,
        autres: Autres,
    };

    constructor(props)
    {
        super(props);
        this.gotoSection = this.gotoSection.bind(this);
    }

    gotoSection(section)
    {
        startingamesRouteManager.goto('/_settings/'+section);
    }

    render ()
    {
        let self = this;
        let sectionName = this.props.route[0];


        let SectionComponent = this.sectionsList[sectionName];
        if(SectionComponent==null) { SectionComponent = this.sectionsList['informations']; sectionName="informations"; }

        return (
            <>
                <div className="flex-col">
                    
                    <WrapperContent>
                        <TopBar></TopBar>
                        <div className={styles['menu-title']}>Paramètres</div>
                        <div className="flex">
                            <div className={styles['menu']} >
                                <div className={styles['menu-name']}>
                                    <button className={"m-auto w-full " + styles['button-icon']} onClick={() => startingamesRouteManager.goto('/')}> 
                                        <FontAwesomeIcon icon={["fas", "arrow-left"]} />
                                    </button>
                                </div>

                                <div className={"flex flex-col " + styles['menu-menu']}>
                                    <div className="my-auto" style={{overflowX: "auto"}}>
                                    {Object.keys(this.sectionsList).map(function(key, index) {
                                        return (
                                            <div className={"flex flex-col "+styles['menu-menu-el'] + (key === sectionName ? " " + styles['selected'] : "")} onClick={function(){self.gotoSection(key)}}>
                                                <div className="my-auto">
                                                    <FontAwesomeIcon icon={["fas", "info"]} style={{marginRight: "0.5vw"}} />
                                                    {self.sectionsList[key].getName()}
                                                </div>
                                            </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                        
                            </div>

                            <div className={styles['page']} >
                            <p style={{fontSize: "5vmin"}}>{SectionComponent.getName()}</p>
                                <SectionComponent/>
                            </div>
                        </div>
                    </WrapperContent>
                </div>
            </>
        );
    }
};