import React, {Component} from 'react';
import styles from './MainMenu.module.scss';

import './App.scss';

import startingamesRouteManager from './startingames/startingamesRouteManager';
import WrapperContent from './components/WrapperContent';
import Button001 from './components/Button001';
import TilesContainer001 from './components/TilesContainer001';
import ButtonTemplate001 from './components/ButtonTemplate001';
import {APP_VERSION_MMFN} from './version';
import ExternalLink from './ExternalLink';
import TopBar from './TopBar';
import { isElectron } from './helpers';

let ipc = null;

if(isElectron())
{
    const { ipcRenderer } = window.require('electron');
    ipc = ipcRenderer;
}

class MainMenu extends Component
{
    selectPackage(packName)
    {
        startingamesRouteManager.goto("/" + packName + "/home");
    }

    opendevtool()
    {
        ipc?.send('request-window', "devtools");
    }

    render ()
    {
        document.getElementById('reactloaded').value = 1;

        let minCol = 5;
        let minRow = 2;

        return (
            <WrapperContent >

                <TopBar></TopBar>

                <div className={"flex flex-wrap content-center h-100 w-100 " + styles['wrapper-home']} >
                    <TilesContainer001 col={minCol} minRow={minRow} maxHeight='90'>

                        <Button001 onClick={() => this.selectPackage("cfr")} style={{background: "rgb(255, 255, 0)"}}>
                            <ButtonTemplate001 catalog={{
                                "bg": "rgb(255, 255, 0)",
                                "color": "rgb(0, 0, 0)",
                                "title": "CFR",
                                "icon": "/./packages/cfr/icon.png"
                            }}/>
                        </Button001>

                        <Button001 onClick={() => this.selectPackage("piap")} style={{background: "rgb(192, 0, 0)"}}>
                            <ButtonTemplate001 catalog={{
                                "bg": "rgb(192, 0, 0)",
                                "title": "PIAP",
                                "icon": "/./packages/piap/icon.png"
                            }}/>
                        </Button001>

                        <Button001 onClick={() => this.selectPackage("sap")} style={{background: "rgb(46, 117, 182)"}}>
                            <ButtonTemplate001 catalog={{
                                "bg": "rgb(46, 117, 182)",
                                "title": "SAP",
                                "icon": "/./packages/sap/icon.png"
                            }}/>
                        </Button001>

                        <Button001 onClick={() => this.selectPackage("cod")} style={{background: "rgb(255, 255, 255)", color:"black"}}>
                            <ButtonTemplate001 catalog={{
                                "bg": "rgb(255, 255, 255)",
                                "color": "rgb(0, 0, 0)",
                                "title": "COD",
                                "icon": "/./packages/cod/icon.png"
                            }}/>
                        </Button001>

                        <Button001 onClick={() => this.selectPackage("avord")} style={{background: "rgb(255, 0, 0)", color:"black"}}>
                            <ButtonTemplate001 catalog={{
                                "bg": "rgb(255, 0, 0)",
                                "color": "rgb(0, 0, 0)",
                                "title": "PLANS",
                                "icon": "/./packages/avord/icon.png"
                            }}/>
                        </Button001>

                    </TilesContainer001>

                </div>

                <div className="flex" style={{background: "repeating-linear-gradient(-45deg, #FFF, #FFF 5px, #F00 5px, #F00 7px)", border: "solid", borderColor: "red", borderWidth: "1vmin", width: "98vw", height: "15vh", bottom: "8vh", left: "1vw", position: "absolute", borderRadius: "4vmin", cursor:"pointer"}} onClick={() =>{startingamesRouteManager.goto('/cfr/crash');}}>
                    <div className="flex w-full h-full">
                    <div className="m-auto" style={{color: "red", fontSize: "10vmin", fontWeight: "700", lineHeight: "1vmin"}}>
                        CRASH
                    </div>
                    </div>
                </div>

                <footer>
                    <nav className={"flex justify-between text-white-dt flex-row"}>
                        <div className={"my-auto"} style={{marginLeft: '1vw'}}>
                            <span><span onDoubleClick={this.opendevtool}>©</span> Antony MARCAGGI - <ExternalLink href="http://redirect-from.startingames.fr/app/salamandre" className={"hover:underline"}>Startingames</ExternalLink> _ Tout droits réservés</span> 
                        </div>

                        <div className={"flex flex-row"}>
                            <div className={"my-auto"} style={{marginRight: '1vw'}}>
                                <span>FAD - {APP_VERSION_MMFN()}</span>
                            </div>
                        </div>
                    </nav>
                </footer>

            </WrapperContent>
        );
    }
};

export default MainMenu; //Export to global