import React, {Component} from 'react';

import '../App.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import geolocManager from '../store/geoloc';
import { connect } from 'react-redux';
import { datetimeSeletor } from '../store/datetime';
import GPSMap from './GPSMap';
import { getPU } from '../helpers';
import PageTemplate001 from './PageTemplate001';
library.add(fas);

class GPSPageComponent extends Component
{
    renderGPSalt()
    {
        if(getPU(geolocManager.get(), "alt", null)!=null)
        {
            return geolocManager.get().alt.toFixed(2) + " m ± " + geolocManager.get().altaccur?.toFixed(2) + " m";
        }
        return "Non déterminée";
    }

    renderGPStime()
    {
        if(getPU(geolocManager.get(), "time", null)!=null)
        {
            let tmp = new Date() - geolocManager.get().time;
            tmp = Math.floor(tmp/1000);             // Nombre de secondes entre les 2 dates
            let sec = tmp % 60;                     // Extraction du nombre de secondes
            let min = Math.floor((tmp-sec)/60);     // Nombre de minutes (partie entière)

            sec = "00" + sec; sec = sec.substr(sec.length-2);
            min = "00" + min; min = min.substr(min.length-2);

            if(min>59) { return "> 1h" }
            else { return min + " m " + sec + " s"; }
        }
        return "-";
    }

    render ()
    {
        let lat = geolocManager.get().lat.toFixed(5);
        let long = geolocManager.get().long.toFixed(5);
        let accur = geolocManager.get().accur.toFixed(0);
        if(geolocManager.get().accur<1) { accur = "<1" }
        if(geolocManager.get().accur>999) { accur = ">1k" }

        return (
            <PageTemplate001 title="Carte et Position GPS">
                {geolocManager.get().state===0 &&
                    <div style={{position: "relative", width: "100%", height: "100%", fontSize: "calc(clamp(1.5vmin, 20px, 3vmin))"}}>
                        <GPSMap unupdate={true} style={{position: "absolute", width: "100%", height: "100%"}} lat={lat} long={long} />
                    
                        <div className="flex" style={{position: "absolute", left: 0, bottom: 0, right: 0, pointerEvents: "none"}}>
                            <div title="Temps depuis la dernière location" className="flex" style={{borderTopLeftRadius: "1vmin", borderTopRightRadius: "1vmin", margin: "auto", padding: "0.2vmin 0.8vmin", background: "rgba(80, 110, 170, 0.8)", pointerEvents:"auto"}}>
                                <FontAwesomeIcon style={{margin: "auto 1vmin"}} icon={["fas", "exclamation-triangle"]} />
                                <div>Impossible de récupérer la position GPS !<br/>
                                Avez-vous autorisé FAD à utiliser la géolocatisation ?</div>
                                <FontAwesomeIcon style={{margin: "auto 1vmin"}} icon={["fas", "exclamation-triangle"]} />
                            </div>
                        </div>
                    </div>
                }
                {geolocManager.get().state===1 &&
                    <div style={{position: "relative", width: "100%", height: "100%", fontSize: "calc(clamp(1.5vmin, 20px, 3vmin))"}}>
                        <GPSMap unupdate={true} style={{position: "absolute", width: "100%", height: "100%"}} gpsok={true} lat={lat} long={long} />

                        <div title="Altitude" style={{borderTopRightRadius: "1vmin", padding: "0.2vmin 0.8vmin", position: "absolute", left: 0, bottom: 0, background: "rgba(80, 110, 170, 0.8)"}}>
                            <FontAwesomeIcon style={{marginRight: "0.5vmin"}} icon={["fas", "mountain"]} />
                            {this.renderGPSalt()} 
                        </div>
                    
                        <div className="flex" style={{position: "absolute", left: 0, bottom: 0, right: 0, pointerEvents: "none"}}>
                            <div title="Temps depuis la dernière location" style={{borderTopLeftRadius: "1vmin", borderTopRightRadius: "1vmin", margin: "auto", padding: "0.2vmin 0.8vmin", background: "rgba(80, 110, 170, 0.8)", pointerEvents:"auto"}}>
                                <FontAwesomeIcon style={{marginRight: "0.5vmin"}} icon={["fas", "history"]} />
                                {this.renderGPStime()}
                            </div>
                        </div>
                    
                        <div style={{borderBottomLeftRadius: "1vmin", padding: "0.2vmin 0.8vmin", position: "absolute", right: 0, top: 0, background: "rgba(80, 110, 170, 0.8)"}}>
                            <div>Latitude : {lat}</div>
                            <div>Longitude : {long}</div>
                            <div>Precision : ± {accur}m</div>
                        </div>
                    </div>
                }
            </PageTemplate001>
        );
    }
};

const GPSPage = connect(
    (state) => ({
        datetime: datetimeSeletor(state)
    })
)(GPSPageComponent)

export default GPSPage