import React, {Component} from 'react';
import { isElectron } from './helpers';

export default class ExternalLink extends Component
{
    constructor(props)
    {
        super(props);
        this.openLink = this.openLink.bind(this);
    }

    openLink()
    {
        const { ipcRenderer } = window.require('electron');
        ipcRenderer.send('request-externalLink', this.props.href);
    }

    render ()
    {
        if(isElectron())
        {
            return (<a onClick={this.openLink} className={this.props.className}>{this.props.children}</a>);
        }
        return (<a target="_blank" rel="noopener noreferrer" href={this.props.href} className={this.props.className}>{this.props.children}</a>);
    }
};
