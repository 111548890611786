import React, { Component } from 'react';
import styles from './Legal.module.scss';

export default class Legal extends Component
{
  render() {
    return (
        <div className={styles['container']}>
            <p className={styles['title']}>Editeur / Responsable éditorial</p>
            <p className={styles['bold']}>Nicolas CHUTIN</p>
            <p>18150 La Guerche sur l'Aubois</p>
            <a href="contact@chutin-nicolas.com">contact@chutin-nicolas.com</a>
            
            <p className={styles['title']}>Hébergement</p>
            <p className={styles['bold']}>OVH</p>
            <p>2 rue Kellermann</p>
            <p>59100 Roubaix - France</p>
            <a href="www.ovh.com">www.ovh.com</a>
        </div>
    )
  }
}