import React, {Component} from 'react';
import styles from './TilesContainer001.module.scss';

import '../App.scss';
import { getPU } from '../helpers';

export default class TilesContainer001 extends Component
{
    render ()
    {
        let localMaxWidth = Math.trunc(1000 * this.props.col * this.props.maxHeight / this.props.minRow) / 1000;

        return (
            <div className={"m-auto grid " + styles['tilesContainer']} style={{ gap: getPU(this.props, "gap", "3vmin"), gridTemplateColumns: "repeat("+this.props.col+", minmax(0, 1fr))", maxWidth: (localMaxWidth + 'vmin') }}>
                {this.props.children}
            </div>
        );
    }
};