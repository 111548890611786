import React, { Component } from 'react';
import styles from './ProjectCard.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { getPU } from '../helpers';
import startingamesRouteManager from '../startingames/startingamesRouteManager';
library.add(fas);

export default class ProjectCard extends Component
{
    render() {
        return (
        <div className={styles['projectCard']} onClick={() => startingamesRouteManager.goto(this.props.slug)}><div>
            <div className={styles['image']}>
                <div className={"flex justify-center h-full w-full"+styles['imagedata']}>
                        <img src={this.props.img} alt="" style={{ verticalAlign: "middle", objectFit: "contain"}}></img>
                </div>
                <div>
                    <div className={styles['date']}> {this.props.date}</div>
                </div>
            </div>
            <div className={styles['title']}>{this.props.title}</div>
            <div className={styles['desc']}>{this.props.desc}</div>
            <div className={styles['more']}>Plus d'infos...</div>
        </div></div>
        )
    }
}