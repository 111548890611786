export const APP_VERSION_NAME = "BETA 2";
export const APP_VERSION_MAJOR = 0;
export const APP_VERSION_MINOR = 4;
export const APP_VERSION_FIX = 0;
export const APP_BUILD = 0;

export function APP_VERSION_MMF()
{
    return "v" + APP_VERSION_MAJOR + "." + APP_VERSION_MINOR + "." + APP_VERSION_FIX;
}

export function APP_VERSION_MMFN()
{
    return "v" + APP_VERSION_MAJOR + "." + APP_VERSION_MINOR + "." + APP_VERSION_FIX + " " + APP_VERSION_NAME;
}