import React, {Component} from 'react';

import '../App.scss';
import { getPackageDataPath, getPU, processLink } from '../helpers';
import Template001 from '../components/Template001';
import styles from './Image001.module.scss';
import Panzoom from '@panzoom/panzoom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);

export class ImageBasic001Displayer extends Component
{
    render ()
    {
        return (
            <Image001Common catalog={this.props.catalog}>
                <img src={getPackageDataPath(this.props.catalog.img)} alt="" style={{ verticalAlign: "middle", objectFit: "contain"}}/>
            </Image001Common>
        );
    }
};

export default class Image001Displayer extends Component
{
    render ()
    {
        return (
            <Image001Common catalog={this.props.catalog}>
                <svg width="100%" height="100%" viewBox={"0 0 "+this.props.catalog.img[1]+" "+this.props.catalog.img[2]} preserveAspectRatio="xMidYMid meet">
                    <image width="100%" height="100%" xlinkHref={getPackageDataPath(this.props.catalog.img[0])} />

                    {this.props.catalog.imgLinks.map(element => {
                    return (
                        <path key={JSON.stringify(element)} className={styles['pathlink']} d={element.path} onClick={() => processLink(element.link)} />
                        );
                    })}
                </svg>
            </Image001Common>
        );
    }
};

export class Image001Common extends Component
{
    constructor(props)
    {
        super(props);
        this.zoomIn = this.zoomIn.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
        this.zoomReset = this.zoomReset.bind(this);
        this.pz = null;
    }

    zoomIn()
    {
        if(this.pz === null) {return;}
        this.pz.zoomIn();
    }

    zoomOut()
    {
        if(this.pz === null) {return;}
        this.pz.zoomOut();
    }

    zoomReset()
    {
        if(this.pz === null) {return;}
        this.pz.reset();
    }

    render ()
    {
        return (
            <Template001 catalog={this.props.catalog}>
                <div style={{position: "relative", height: "100%", width: "100%"}}>

                    <div style={{height: "100%", width: "100%", overflow: "auto"}}>
                        <div style={{height: getPU(this.props, "height", "100%"), width: getPU(this.props, "width", "100%")}}>
                            <div id="panzoom-element" className="flex justify-center" style={{overflow: 'hidden', width: "100%", height: "100%"}}>
                                {this.props.children}
                            </div>
                        </div>
                    </div>

                    <div className={styles['zoombar']}>
                        <button className={"flex w-full h-full"} onClick={() => this.zoomIn()}>
                            <FontAwesomeIcon className={"m-auto"} icon={["fas", "search-plus"]} />
                        </button>
                        <button className={"flex w-full h-full"} onClick={() => this.zoomOut()}>
                            <FontAwesomeIcon className={"m-auto"} icon={["fas", "search-minus"]} />
                        </button>
                        <button className={"flex w-full h-full"} onClick={() => this.zoomReset()}>
                            <FontAwesomeIcon className={"m-auto"} icon={["fas", "times"]} />
                        </button>
                    </div>

                </div>
            </Template001>
        );
    }

    componentDidMount()
    {
        this.pz = Panzoom(document.getElementById('panzoom-element'), { minScale: 0.999, startScale: 1, panOnlyWhenZoomed: true });
        document.getElementById('panzoom-element').addEventListener('panzoomchange', (event) => {
            if(this.pz.getScale()<1)
            {
                this.pz.reset();
            }
        })
    }
};