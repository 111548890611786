
import { connect } from 'react-redux';
import './App.scss';

import MainMenu from './MainMenu.jsx'

import store from './store';
import { Component } from 'react';
import startingamesRouteManager, { startingamesRouteManagerSeletor } from './startingames/startingamesRouteManager';
import Settings from './settings';
import { catalogLoad, catalogUpdate } from './store/catalogActions';
import axios from 'axios';
import { Displayer } from './displayers';
import { getPU, getRootPath, initApp } from './helpers';

//import history from 'history/browser'
import { createHashHistory } from 'history'
import { datetimeUpdate } from './store/datetime';
import geolocManager from './store/geoloc';
import ClockPage from './pages/ClockPage';
import GPSPage from './pages/GPSPage';
import Wrapper001 from './components/Wrapper001';
import MainTemplate from './components/MainTemplate';
import Home from './components/Home';
import Projects from './components/Projects';
import Contact from './components/Contact';
import { projectsManager } from './store/projectsManager';
import Legal from './components/Legal';
var history = createHashHistory();

let linkToHistory = {
      listen: function(callback){ history.listen(callback) },
      push: function(path, state){ history.push(path, state) },
      replace: function(path, state){ history.replace(path, state) },
      back: function(){ history.back() },
      forward: function(){ history.forward() },
      init: function(path){return history.location.pathname },
    };

startingamesRouteManager.init(store, "/", linkToHistory);
initApp();

function secondUpdate()
{
  let datetimerow = new Date();
  let datetime = {
  "hours": datetimerow.getHours(),
  "minutes": datetimerow.getMinutes(),
  "seconds": datetimerow.getSeconds(),
  "wday": datetimerow.getDay(),
  "day": datetimerow.getDate(),
  "month": datetimerow.getMonth()+1,
  "year": datetimerow.getFullYear() }

  store.dispatch(datetimeUpdate(datetime));
}
setInterval(secondUpdate, 1000);

geolocManager.init(store);

projectsManager.init();


class App extends Component
{
    constructor(props)
    {
        super(props);
        this.package = "";
        this.page = "";
        this.tags = "";
        this.catalogRow = null;
        this.state = {ploaded: false};
        projectsManager.load(this.loaded.bind(this));
    }

    loaded()
    {
      this.setState({ploaded: true});
      document.getElementById('reactloaded').value=1;
    }

    render ()
    {
      let routesplit = this.props.route.path.split('/');
      routesplit.shift(); //Remove the first element (useless)

      if(this.props.route.path==="/")
      {
        return(<Wrapper001> <MainTemplate menu="/"> <Home/> </MainTemplate> </Wrapper001>);
      }

      if(routesplit[0]==="projets")
      {
        if(routesplit.length>1)
        {
          return(<Wrapper001 title={projectsManager.getProjects()[routesplit[1]].title}></Wrapper001>);
        }
        return(<Wrapper001> <MainTemplate menu="projets"> <Projects/> </MainTemplate> </Wrapper001>);
      }

      if(routesplit[0]==="contact")
      {
        return(<Wrapper001> <MainTemplate menu="contact"> <Home/> </MainTemplate> </Wrapper001>);
      }

      if(routesplit[0]==="mentions_legales")
      {
        return(<Wrapper001 title="Mentions Légales"> <Legal/> </Wrapper001>);
      }

      return(<Wrapper001 title={this.props.route.path}></Wrapper001>);
    }
};

export const AppComponent = connect(
  (state) => ({
      route: startingamesRouteManagerSeletor(state)
  }),
  (dispatch) => ({
      updateCatalog: catalog => dispatch(catalogUpdate(catalog)),
      loadCatalog: catalog => dispatch(catalogLoad(catalog))
  })
)(App)