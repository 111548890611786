import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);

export default class Home extends Component
{
  render() {
    return (
      <div className="flex h-full w-full" style={{padding: "10vmin"}}>
        <div className="m-auto" style={{fontSize: "10vmin", textAlign: "center"}}>
          <FontAwesomeIcon style={{fontSize: "20vmin"}} icon={["fas", "tools"]} /><br/>
          Construction en cours ...
        </div>
      </div>
    )
  }
}