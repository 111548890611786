import React, {Component} from 'react';
//import styles from './ClockGUI.module.scss';
import styled from "styled-components";

const Svg = styled.svg`
  max-width: 500px;
  display: block;
  margin: 0 auto;
`;

const Frame = styled.circle`
  stroke: black;
  fill: white;
  stroke-width: 10;
`;

const SecondsHand = styled.g`
  stroke: red;
`;
const MinutesHand = styled.g`
  stroke: black;
`;
const HoursHand = styled.g`
  stroke: black;
`;

const minuteMarkers = Array.from(new Array(60), (x, i) => i);
const fiveMinuteMarkers = minuteMarkers.filter(m => m % 5 === 0);

const markerIndexToRadians = markerIndex => (Math.PI * markerIndex) / 30;

const drawMinuteMarker = markerIndex => {
  return (
    <g style={{ stroke: "black" }}>
      <line
        x1={94 * Math.cos(markerIndexToRadians(markerIndex))}
        y1={94 * Math.sin(markerIndexToRadians(markerIndex))}
        x2={100 * Math.cos(markerIndexToRadians(markerIndex))}
        y2={100 * Math.sin(markerIndexToRadians(markerIndex))}
      />
    </g>
  );
};

const draw5MinMarker = markerIndex => {
  return (
    <g style={{ stroke: "black" }}>
      <line
        strokeWidth="2"
        x1={90 * Math.cos(markerIndexToRadians(markerIndex))}
        y1={90 * Math.sin(markerIndexToRadians(markerIndex))}
        x2={100 * Math.cos(markerIndexToRadians(markerIndex))}
        y2={100 * Math.sin(markerIndexToRadians(markerIndex))}
      />
    </g>
  );
};

const hourLabels = ["3","4","5","6","7","8","9","10","11","12","1","2"];

const drawHourLabel = (label, hourLabelIndex) => {
  return (
    <text
      textAnchor="middle"
      alignmentBaseline="central"
      dominantBaseline="central"
      x={80 * Math.cos(markerIndexToRadians(hourLabelIndex * 5))}
      y={80 * Math.sin(markerIndexToRadians(hourLabelIndex * 5))}
    >
      {label}
    </text>
  );
};

export default class ClockGUI extends Component
{
    render ()
    {
        return (
            <Svg width={this.props.width} height={this.props.height} viewBox="0 0 300 300" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(150,150)">
                    <Frame r="110" />

                    <circle></circle>

                    {minuteMarkers.map((m, i) => ( <React.Fragment key={i}>{drawMinuteMarker(m)}</React.Fragment> ))}
                    {fiveMinuteMarkers.map((m, i) => ( <React.Fragment key={i}>{draw5MinMarker(m)}</React.Fragment> ))}
                    {hourLabels.map((l, i) => ( <React.Fragment key={i}>{drawHourLabel(l, i)}</React.Fragment> ))}

                    <HoursHand transform={`rotate(${this.props.time.hours * 30 + (this.props.time.minutes / 60) * 30},0,0)`} >
                        <line strokeWidth="4" x1="0" y1="15" x2="0" y2="-60" />
                    </HoursHand>

                    <MinutesHand transform={`rotate(${this.props.time.minutes * 6},0,0)`}>
                        <line strokeWidth="2" x1="0" y1="20" x2="0" y2="-80" />
                    </MinutesHand>

                    <SecondsHand transform={`rotate(${this.props.time.seconds * 6},0,0)`}>
                        <line x1="0" y1="30" x2="0" y2="-100" />
                    </SecondsHand>

                    <circle fill="red" r="3" />
                </g>
            </Svg>
        );
    }
};