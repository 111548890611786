/* ************************************************************************************
 * startingamesRouteManager.js  - Startingames Route Manager
 * 
 * ***********************************************************************************/

// ************************************************************************************
// REDUX
// ************************************************************************************
export function startingamesRouteManagerReducer(state = { "path":"" }, action)
{
    switch(action.type)
    {
        case 'ROUTE.CHANGE':
            return { "path":action.payload.newPath }
        
        default:
            return state
    }
}

export const startingamesRouteManagerSeletor = ({route}) => route

const startingamesRouteManagerUpdate = (route) => ({
    type: 'ROUTE.CHANGE',
    payload: {newPath: route}
})

// ************************************************************************************
// CLASS
// ************************************************************************************
let startingamesRouteManagerStore;
let startingamesRouteManagerLocalHistory;
let startingamesRouteManagerLocalHistoryIndex;
let startingamesRouteManagerRootPath;
let startingamesRouteManagerHistory = {
  listen: function(callback){},
  push: function(path, state){},
  replace: function(path, state){},
  back: function(){},
  forward: function(){},
  init: function(path){return path},
};

export default class startingamesRouteManager
{
  //Internal Function
  static toStore(path)
  {
    //console.log("To Store : "+startingamesRouteManagerLocalHistoryIndex); console.log(startingamesRouteManagerLocalHistory);
    startingamesRouteManagerStore.dispatch(startingamesRouteManagerUpdate(path));
  }

  /** init(linkToStore, rootPath="", linkToHistory = null, defaultStartPath = "/")
  *** @linkToStore The store used (REDUX)
  *** @rootPath The app default path (used when goBack without previous page) - optional - "" to disable (default)
  *** @linkToHistory The history functions list provider - optional - null if no history (standalone mode) (default)
  *** @defaultStartPath The APP start path if no history - optional - default is ""
   */
  static init(linkToStore, rootPath="", linkToHistory = null, defaultStartPath = "/")
  {
    startingamesRouteManagerRootPath = rootPath;
    if(linkToHistory!==null) { startingamesRouteManagerHistory=linkToHistory; }

    startingamesRouteManagerLocalHistory = [];
    startingamesRouteManagerLocalHistory.push(startingamesRouteManagerHistory.init(defaultStartPath));
    startingamesRouteManagerLocalHistoryIndex = 0;

    startingamesRouteManagerStore=linkToStore;
    startingamesRouteManagerStore.dispatch(startingamesRouteManagerUpdate(startingamesRouteManagerHistory.init(defaultStartPath)));

    startingamesRouteManagerHistory.replace(startingamesRouteManagerHistory.init(defaultStartPath), 0);

    startingamesRouteManagerHistory.listen(this.historyListen.bind(this));
  }

  //Internal Function
  static historyListen({action, location}) {
      console.log("history listen : "+action); console.log(location);
      if(action==='POP') //Change from browser => update route
      {
        //console.log("Current : "+startingamesRouteManagerLocalHistoryIndex+" - state : "+location.state);
        let historyIndex = location.state;
        if(historyIndex<1) { historyIndex = 0; }
        if(historyIndex>=startingamesRouteManagerLocalHistory.length)
        {
          console.error("Startingames Route Manager - ERROR index > lenght");
        }
        else if(historyIndex !== startingamesRouteManagerLocalHistoryIndex)
        {
          startingamesRouteManagerLocalHistoryIndex=historyIndex;
          this.toStore(startingamesRouteManagerLocalHistory[startingamesRouteManagerLocalHistoryIndex]);
        }
      }
    }

  static goto(newLink)
  {
    if(this.getPath()===newLink) {return;}

    while(startingamesRouteManagerLocalHistoryIndex<(startingamesRouteManagerLocalHistory.length-1))
    {
      startingamesRouteManagerLocalHistory.pop();
    }
    startingamesRouteManagerLocalHistory.push(newLink);
    startingamesRouteManagerLocalHistoryIndex++;
    this.toStore(newLink);
    startingamesRouteManagerHistory.push(newLink, startingamesRouteManagerLocalHistoryIndex);
  }

  static goBack()
  {
    if(startingamesRouteManagerLocalHistoryIndex<1)
    {
      if(startingamesRouteManagerRootPath!=="")
      {
        this.goto(startingamesRouteManagerRootPath);
      }
      return;
    }

    startingamesRouteManagerLocalHistoryIndex--;
    this.toStore(startingamesRouteManagerLocalHistory[startingamesRouteManagerLocalHistoryIndex]);
    startingamesRouteManagerHistory.back();
  }

  /*static goForward()
  {
    // History Index ++
    // Load Hisory[Index] into Store
    // history.forward();
  }*/

  /*static replace()
  {
    // Remove all more recent entry
    // Replace in Local History
    // Load into Store
    // history.replace whit same index and new url
  }*/

  static getRoute()
  {
    //console.log(this.getRoute());
    return startingamesRouteManagerSeletor(startingamesRouteManagerStore.getState());
  }

  static getPath()
  {
    return this.getRoute().path;
  }
};

