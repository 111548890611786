import React, {Component} from 'react';
import styles from './PageTemplate001.module.scss';

import '../App.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import startingamesRouteManager from '../startingames/startingamesRouteManager';
import WrapperContent from '../components/WrapperContent';
import TopBar from '../TopBar';
library.add(fas);

export default class PageTemplate001 extends Component
{
    render ()
    {
        return (
            <WrapperContent className="flex">
                <TopBar></TopBar>

                <div className={"flex flex-col w-full"}>

                    <div className={"flex "+styles['titlebar']} style={{fontSize: "5vmin"}}>
                            <button className={"m-auto w-full " + styles['button-icon']} style={{width: "15%"}} onClick={() => startingamesRouteManager.goBack()}> 
                                <FontAwesomeIcon icon={["fas", "arrow-left"]} />
                            </button>
                                
                        <p className={"my-auto w-full"} style={{marginLeft: "1vmin"}}>{this.props.title}</p>
                    </div>

                    <div className={styles['page']} >
                        {this.props.children}
                    </div>

                </div>
                
            </WrapperContent>
        );
    }
};