import React, { Component } from "react";

import 'ol/ol.css';
import Feature from 'ol/Feature';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';
import {Icon, Style} from 'ol/style';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {fromLonLat} from 'ol/proj';

export default class GPSMap extends Component {
  constructor(props) {
    super(props);

    this.olmap = null;
    this.currentLocation = null;
  }

  componentDidMount() {
      this.currentLocation = new Feature({ geometry: new Point(fromLonLat([this.props.long, this.props.lat])), });
      
      this.currentLocation.setStyle(
        new Style({
          image: new Icon({
            color: 'red',
            crossOrigin: 'anonymous',
            // For Internet Explorer 11
            imgSize: [20, 20],
            src: 'data/dot.svg',
          }),
        })
      );
      
      var markerLayer = new VectorLayer({ source: new VectorSource({ features: [this.currentLocation] }) });
      var mapLayer = new TileLayer({ source: new OSM() });
      
      if(this.props.gpsok===true)
      {
        this.olmap = new Map({
          layers: [mapLayer, markerLayer],
          target: document.getElementById('map'),
          view: new View({
              center: fromLonLat([this.props.long, this.props.lat]),
              zoom: 12,
          }),
          });
      }
      else
      {
        this.olmap = new Map({
          layers: [mapLayer],
          target: document.getElementById('map'),
          view: new View({
              center: fromLonLat([this.props.long, this.props.lat]),
              zoom: 0,
          }),
          });
      }
          
    

  }

  shouldComponentUpdate(nextProps, nextState) {
    this.currentLocation = new Feature({ geometry: new Point(fromLonLat([this.props.long, this.props.lat])), });
    if(this.props?.unupdate===true) { return false; }
    let center = this.olmap.getView().getCenter();
    let zoom = this.olmap.getView().getZoom();
    if (center === nextState.center && zoom === nextState.zoom) return false;
    return true;
  }

  render() {
    return (
        <div className={this.props.className} style={{position: "relative", ...this.props.style}}>
            <div className="flex" style={{position: "absolute", width: "100%", height: "100%", background: "rgba(200,200,200,0.4)"}}>
                <div className="m-auto" style={{textAlign: "center"}}>
                    Chargement ...<br/>
                    Une connexion internet est nécessaire.
                </div>
            </div>
            <div id="map" style={{position: "absolute", width: "100%", height: "100%"}}></div>
        </div>
      
    );
  }
}